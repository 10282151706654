<template>
  <div v-if="show && validTime">
    <!--用戶組-->
    <Card v-for="(group, gi) in groups" :key="gi" style="margin-bottom: 5px; text-align: left;">
      <div v-if="group.id >=0" slot="title" style="text-align: left;">
        <InputNumber v-model="group.id" disabled :min="0"></InputNumber>
        <Input v-model="group.name" maxlength="32" style="width: 150px;"></Input>
      </div>
      <p v-else slot="title" style="text-align: left;">新增用戶組</p>

      <span slot="extra">
        <Button icon="ios-checkmark-circle" type="warning" @click="onSaveGroup(gi)"></Button>
        <Button v-if="false && group.id>=0" icon="ios-trash" type="error" @click="onDelGroup(gi)"></Button>
      </span>
      <div v-if="group.id<0" style="text-align: left;">
        <Icon type="ios-people-outline"></Icon>
        <Input v-model="group.name" maxlength="32" style="width: 150px;"></Input>
      </div>
      <Checkbox v-for="(field,fi) in fields" v-model="group[field]" :key="fi">{{labels[field]}}</Checkbox>
    </Card>
    <p style="color: orange;">請慎重操作！</p>
  </div>
</template>

<script>
  import util from '@/libs/util';
  import { httpGet, httpPost, httpDelete } from '@/api/data';

  export default {
    name: 'AdminGroup',
    props: {
      value: Number, // 用于强制刷新
      show: Boolean
    },
    data () {
      return {
        user: this.$store.state.user,
        busy: false,
        validTime: 0,
        group_def: {
          id: -1,
          name: '',
          allowread: true,
          allowthread: false,
          allowpost: false,

          allowattach: false,
          allowdown: false,

          allowtop: false,
          allowupdate: false,
          allowdelete: false,
          allowmove: false,
          allowbanuser: false,
          allowdeleteuser: false,
          allowviewip: false
        },
        groups: [],
        raws: [],
        fields: [],
        labels: {
          allowread: '主題列表',
          allowpost: '查看帖子',
          allowthread: '發表/回復',

          allowattach: '上傳附件',
          allowdown: '下載附件',

          allowtop: '置頂',
          allowupdate: '修改',
          allowdelete: '刪除',
          allowmove: '移動',
          allowbanuser: '修改用戶組',
          allowdeleteuser: '刪除用戶',
          allowviewip: '查看IP'
        }
      }
    },
    computed: {
      isSupper () {
        return util.isSupper(this.$store.state.user);
      }
    },
    methods: {
      refresh (force) {
        if (force || this.validTime < new Date().getTime()) {
          this.onRefresh();
        }
      },
      refreshTime () {
        this.validTime = new Date().getTime() + 60 * 1000;
      },
      onRefresh () {
        if (this.busy) {
          return;
        }
        this.busy = true;
        this.groups.splice(0);
        this.raws.splice(0);
        httpGet('/v1/bbs/admin/groups').then(res => {
          this.groups = res;
          this.raws = JSON.parse(JSON.stringify(res));
        }).catch(() => {
        }).finally(() => {
          this.onDataRefresh();
          this.refreshTime();
          this.busy = false;
        });
      },
      appendNew () {
        if (this.groups.length === 0) {
          this.groups.push({ ...this.group_def });
        } else {
          const group = { ...this.groups[0] };
          for (const f in group) {
            const v = this.group_def[f];
            if (v !== undefined) {
              group[f] = v;
            }
          }
          this.groups.push(group);
        }
      },
      onDataRefresh () {
        // 添加一个新的
        // this.appendNew();
        const group = this.groups.length > 0 ? this.groups[0] : this.group_def;
        this.fields.splice(0);
        for (const f in group) {
          // 以allow开头的属性都认为是权限
          if (f.substring(0, 5) === 'allow') {
            this.fields.push(f);
            if (!this.labels[f]) {
              this.labels[f] = f;
            }
          }
        }
      },
      onSaveGroup (index) {
        if (this.busy) {
          return;
        }
        let apiUrl = '/v1/bbs/admin/group';
        const group = this.groups[index];
        if (group.id < 0) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: 'ID不能為負！'
          });
          return;
        }
        const postData = group;
        if (index < this.raws.length) {
          // 比較是否有修改
          const raw = this.raws[index];
          let same = true;
          for (const i in postData) {
            if (raw[i] !== postData[i]) {
              same = false;
              break;
            }
          }
          if (same) {
            return;
          }
          apiUrl += '/' + raw.id
        }

        // 比較是否有相同ID和名稱
        for (let i = 0; i < this.raws.length; i++) {
          if (i === index) {
            continue;
          }
          const raw = this.raws[i];
          if (raw.id === postData.id) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: '存在相同ID！'
            });
            return;
          }
          if (raw.name === postData.name) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: '存在相同名稱！'
            });
            return;
          }
        }
        // 提交
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(res => {
          this.groups[index] = res;
          this.raws[index] = { ...res };
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onDelGroup (index) {
        const group = this.groups[index];
        const apiUrl = '/v1/bbs/admin/group/' + group.id;
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '正在進行危險操作！確定刪除用戶分組？',
          onOk: () => {
            this.busy = true;
            httpDelete(apiUrl).then(() => {
              this.groups.splice(index, 1);
              this.raws.splice(index, 1);
            }).catch(() => {
            }).finally(() => {
              this.busy = false;
            });
          }
        });
      }
    },
    watch: {
      value () {
        this.refresh(true);
      },
      show (val) {
        if (val) {
          this.refresh();
        }
      }
    }
  }
</script>

<style scoped>
</style>
