<template>
  <div v-if="show && validTime">
    <Card style="margin-bottom: 5px;">
      <p slot="title" style="text-align: left;">搜索條件</p>
      <Button slot="extra" type="primary" @click="onSearch">搜索</Button>
      <div style="display: table;">
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">搜索類型：</span>
          <span style="display: table-cell;width: 10px;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">關鍵詞：</span>
        </div>
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">
            <Select style="width: 200px;" v-model="user_field">
              <i-option value="uname">用戶名</i-option>
              <i-option value="nick">昵稱</i-option>
              <i-option value="mobile">手機號</i-option>
            </Select>
          </span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">
            <Input style="width: 300px;" v-model="keyword" autofocus></Input>
          </span>
        </div>
      </div>
    </Card>
    <Page :current="req_page" :total="totals" :page-size="page_size" @on-change="page_change"
          size="small" class="pager" style="text-align: right;"
          :transfer="true" show-elevator show-total/>
    <Table :columns="user_columns" :data="user_list" :loading="loading" no-data-text="沒有結果" border>
      <template slot-scope="{ row }" slot="id">
        <span v-if="row.id<=0">*</span>
        <span v-else>{{row.id}}</span>
      </template>
      <template slot-scope="{row, index}" slot="group">
        <Button v-if="isSupper" @click="onSetGroup(index)" type="primary" size="small" icon="ios-people">
          {{group_names[row.gid]}}
        </Button>
        <span v-else>{{group_names[row.gid]}}</span>
      </template>
      <template slot-scope="{ row }" slot="mobile">
        <span v-if="!row.mobile"></span>
        <span v-else-if="row.state_code">{{row.state_code+' '+row.mobile}}</span>
        <span v-else>{{row.mobile}}</span>
      </template>
      <template slot-scope="{ row }" slot="regtime">
        <span >{{timeStamp(row.created_at)}}</span>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button v-if="isSupper" @click="onDelUser(index)" type="error" size="small" icon="ios-trash"></Button>
      </template>
    </Table>
    <Page :current="req_page" :total="totals" :page-size="page_size" @on-change="page_change"
          size="small" class="pager" style="text-align: right;"
          :transfer="true" show-elevator show-total/>
    <p style="color: orange;"></p>

    <Modal v-model="selectGroup" width="230">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-people"></Icon>選擇用戶組
      </p>
      <div>
        <Select style="width: 200px;" v-model="group_id">
          <i-option v-for="(g, gi) in groups" :value="g.id" :key="gi">
            {{g.name}}
          </i-option>
        </Select>
      </div>
      <Button slot="footer" type="primary" @click="setGroup">設置</Button>
    </Modal>
  </div>
</template>

<script>
  import util from '@/libs/util';
  import { httpGet, httpGetParams, httpPost } from '@/api/data';

  export default {
    name: 'AdminUser',
    props: {
      value: Number, // 用于强制刷新
      show: Boolean
    },
    data () {
      return {
        user: this.$store.state.user,
        busy: false,
        validTime: 0,
        loading: false,
        user_list: [],

        edit_index: -1,
        selectGroup: false,
        group_id: 0,

        user_field: 'uname',
        keyword: null,

        groups: [],
        group_names: {},

        user_columns: [
          {
            title: 'ID',
            slot: 'id',
            width: 60
          },
          {
            title: '用戶名',
            key: 'uname'
          },
          {
            title: '昵稱',
            key: 'nick'
          },
          {
            title: 'EMail',
            key: 'email'
          },
          {
            title: '用戶組',
            slot: 'group',
            width: 120
          },
          {
            title: '手機號',
            slot: 'mobile',
            width: 150
          },
          {
            title: '註冊時間',
            slot: 'regtime',
            width: 120
          },
          {
            title: '操作',
            slot: 'action',
            width: 90
          }
        ],
        totals: 0,
        page_size: 10,
        curr_page: 1,
        req_page: 1
      }
    },
    computed: {
      isAdmin () {
        return util.isAdmin(this.$store.state.user);
      },
      isSupper () {
        return util.isSupper(this.$store.state.user);
      }
    },
    methods: {
      refresh (force) {
        if (force || this.validTime < new Date().getTime()) {
          this.onRefresh();
        }
      },
      refreshTime () {
        this.validTime = new Date().getTime() + 60 * 1000;
      },
      onRefresh () {
        if (this.busy) {
          return;
        }
        this.busy = true;
        this.groups.splice(0);
        this.group_names = {};
        httpGet('/v1/bbs/admin/groups').then(res => {
          this.groups = res;
          for (const i in res) {
            const g = res[i];
            this.group_names[g.id] = g.name;
          }
          this.refreshTime();
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
        });
      },
      page_change (page) {
        this.req_page = page;
        this.onSearch();
      },
      timeStamp (tt) {
        return util.showTimeStamp(tt, true, 0);
      },
      onSearch () {
        if (this.loading) {
          return;
        }
        const kw = this.keyword.trim();
        if (!kw) {
          return;
        }
        this.user_list.length = 0;
        const params = {};
        params[this.user_field] = kw;
        params.page = this.req_page;
        params.pageSize = this.page_size;
        this.loading = true;
        this.$Spin.show();
        httpGetParams('/v1/bbs/admin/users', params).then(res => {
          this.user_list = res.data;
          this.curr_page = res.current_page;
          this.totals = res.total;
        }).catch(() => {
        }).finally(() => {
          this.loading = false;
          this.$Spin.hide();
        });
      },
      onSetGroup (index) {
        this.edit_index = index;
        const user = this.user_list[index];
        this.group_id = user.gid;
        this.selectGroup = true;
      },
      setGroup () {
        if (this.edit_index < 0) {
          this.selectGroup = false;
          return;
        }
        const user = this.user_list[this.edit_index];
        if (this.group_id === user.gid) {
          this.selectGroup = false;
          return;
        }
        const postData = {
          gid: this.group_id
        }
        httpPost('/v1/bbs/admin/user/' + user.id, postData)
          .then((res) => {
            this.user_list[this.edit_index] = res;
          })
          .catch(() => {})
          .finally(() => {
            this.edit_index = -1;
            this.selectGroup = false;
          });
      },
      onDelUser (/* index */) {
        this.$Modal.warning({
          title: this.$t('common.alert_title'),
          content: '暫不支持刪除！'
        });
      }
    },
    watch: {
      value () {
        this.refresh(true);
      },
      show (val) {
        if (val) {
          this.refresh();
        }
      }
    }
  }
</script>

<style scoped>
</style>
