<template>
  <div>
    <Menu theme="light" mode="horizontal" :active-name="menu_tag" @on-select="onMenuChange" style="margin-bottom: 5px;">
      <MenuItem name="forum"><Icon type="ios-chatboxes"/>{{$t('admin.menu_forum')}}</MenuItem>
      <MenuItem name="topic"><Icon type="ios-chatbubbles"/>{{$t('admin.menu_topic')}}</MenuItem>
      <MenuItem v-if="isAdmin" name="user"><Icon type="ios-person"/>{{$t('admin.menu_user')}}</MenuItem>
      <MenuItem v-if="isAdmin" name="group"><Icon type="ios-people"/>{{$t('admin.menu_group')}}</MenuItem>
    </Menu>
    <AdminForum :show="menu_tag=='forum'"></AdminForum>
    <AdminTopic :show="menu_tag=='topic'"></AdminTopic>
    <AdminUser :show="menu_tag=='user'"></AdminUser>
    <AdminGroup :show="menu_tag=='group'"></AdminGroup>
  </div>
</template>

<script>
  import AdminForum from './admin/admin_forum';
  import AdminTopic from './admin/admin_topic';
  import AdminUser from './admin/admin_user';
  import AdminGroup from './admin/admin_group';
  import util from '@/libs/util';

  export default {
    name: 'Admin',
    components: {
      AdminForum,
      AdminTopic,
      AdminUser,
      AdminGroup
    },
    data () {
      return {
        loading: true,

        menu_tag: '' // forum, topic, user, group
      }
    },
    computed: {
      isAdmin () {
        return util.isAdmin(this.$store.state.user);
      },
      isSupper () {
        return util.isSupper(this.$store.state.user);
      }
    },
    methods: {
      initData () {
        this.onMenuChange('forum');
      },
      onMenuChange (tag) {
        // 检查登录时间
        if (this.$store.state.admin.loginTime < new Date().getTime()) {
          this.$router.push({
            name: 'login',
            params: {
              target: this.$route
            }
          });
          return;
        }
        this.$store.commit('enterAdmin', true);
        this.menu_tag = tag;
      }
    },
    mounted () {
      this.initData();
    }
  };
</script>

<style scoped>

</style>
