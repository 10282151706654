<template>
  <div v-if="show && validTime">
    <!--分组-->
    <Card v-for="(category, ci) in categories" :key="'c'+ci" style="margin-bottom: 5px;">
      <div v-if="category.id>0" slot="title" style="text-align: left;">
        <Icon type="ios-chatboxes-outline"></Icon>
        <span v-if="edit_c_index === ci && edit_f_index < 0">
          <Input v-model="edit_c.name" maxlength="50" style="width: 150px;"></Input>
          <Button icon="ios-checkmark-circle" type="warning"
                  @click="onSaveCategory(ci)"></Button>
        </span>
        <span v-else>{{category.name}}</span>
      </div>
      <p v-else slot="title" style="text-align: left;">新增分組</p>
      <div v-if="category.id>0 && isSupper" slot="extra">
        <Button v-if="edit_c_index !== ci || edit_f_index >= 0"
                @click="onEditCategory(ci)"
                size="small" icon="ios-create" type="primary" style="margin-right: 5px;"></Button>
        <Button @click="onDelCategory(ci)"
                size="small" icon="ios-trash" type="error"></Button>
      </div>
      <div v-else-if="isSupper" style="text-align: left;">
        <Icon type="ios-chatboxes-outline"></Icon>
        <span>
          <Input v-model="category.name" maxlength="50"
                 style="width: 150px;"></Input>
          <Button icon="ios-checkmark-circle" type="warning"
                  @click="onSaveCategory(ci)"></Button>
        </span>
      </div>
      <!-- 版块 -->
      <Table v-if="category.id>0" border footer-hide
             :columns="f_columns" :data="category.forums" :key="'f'+ci">
        <template slot-scope="{ row }" slot="id">
          <span v-if="row.id<=0">*</span>
          <span v-else>{{row.id}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="name">
          <Input v-if="row.id<=0" v-model="category.forums[index].name"
                 maxlength="50" :key="'fn_'+row.id"></Input>
          <Input v-else-if="edit_f_index === index" v-model="edit_f.name"
                 maxlength="50" :key="'fn_'+row.id"></Input>
          <span v-else>{{row.name}}</span>
        </template>

        <template v-if="row.id>0" slot-scope="{ row }" slot="master">
          <span v-for="(user, ui) in row.masters" :key="ui" class="master_item">
            <router-link :to="'/user?id='+user.id">{{user.nick ? user.nick:user.uname}}</router-link>
            <Button v-if="isSupper" icon="ios-close-circle" size="small" shape="circle"
                    style="border: none;"
                    @click="onRemoveMaster(row, ui)"></Button>
          </span>
          <Button v-if="isSupper" icon="ios-add-circle" size="small"
                  @click="onAddMaster(row)">添加</Button>
        </template>
        <template v-else slot="master"></template>

        <template slot-scope="{ row, index }" slot="des">
          <Input v-if="row.id<=0" v-model="category.forums[index].brief"
                 maxlength="100" :key="'f_des_'+row.id"></Input>
          <Input v-else-if="edit_f_index === index" v-model="edit_f.brief"
                 maxlength="100" :key="'f_des_'+row.id"></Input>
          <span v-else>{{row.brief}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="notice">
          <Input v-if="row.id<=0" v-model="category.forums[index].announcement"
                 maxlength="100" :key="'f_n_'+row.id"></Input>
          <Input v-else-if="edit_f_index === index" v-model="edit_f.announcement"
                 maxlength="100" :key="'f_n_'+row.id"></Input>
          <span v-else>{{row.announcement}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button v-if="row.id <= 0 || edit_f_index === index && !move_forum"
                  @click="onSaveForum(ci,index)" icon="ios-checkmark-circle"
                  type="warning" size="small" style="margin-right: 5px"></Button>
          <Button v-else-if="row.id > 0 && isSupper"
                  @click="onEditForum(ci,index)" icon="ios-create"
                  type="primary" size="small" style="margin-right: 5px"></Button>
          <Button v-if="row.id > 0 && isSupper"
                  @click="onMoveForum(ci,index)" icon="ios-swap"
                  size="small" style="margin-right: 5px"></Button>
          <br>
          <Button v-if="row.id > 0 && isSupper"
                  @click="onDelForum(ci,index)" icon="ios-trash"
                  type="error" size="small" style="margin-right: 5px"></Button>
          <Button v-if="row.id > 0"
                  @click="onAuthForum(ci,index)" icon="ios-key"
                  size="small"></Button>
        </template>
      </Table>
    </Card>

    <Modal v-model="move_forum" width="230">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-swap"></Icon>移動板塊
      </p>
      <div>
        <Select style="width: 200px;" v-model="move_to_id">
          <i-option v-for="(c, ci) in categories" :value="c.id" :key="ci">
            {{c.name}}
          </i-option>
        </Select>
      </div>
      <Button slot="footer" type="primary" @click="doMoveForum">移動</Button>
    </Modal>

    <Modal v-model="selectMaster" width="350">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-people"></Icon>添加版主
      </p>
      <div style="display: table;">
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">搜索類型：</span>
          <span style="display: table-cell;width: 5px;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">關鍵詞：</span>
          <span style="display: table-cell;">&nbsp;</span>
        </div>
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">
            <Select style="width: 100px;" v-model="user_field">
              <i-option value="uname">用戶名</i-option>
              <i-option value="nick">昵稱</i-option>
              <i-option value="mobile">手機號</i-option>
            </Select>
          </span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">
            <Input style="width: 180px;" v-model="user_keyword" autofocus></Input>
          </span>
          <span style="display: table-cell;text-align: left;">
            <Button @click="onSearchUser" icon="ios-search" style="margin-left: 5px;"></Button>
          </span>
        </div>
      </div>
      <Select v-model="user_id" style="width: 300px;">
        <i-option v-for="(u, ui) in user_list" :value="u.id" :key="ui">
          {{u.uname + (u.nick?'('+u.nick+')':'')}}
        </i-option>
      </Select>
      <Button slot="footer" type="primary" @click="addMaster">選擇</Button>
    </Modal>

    <!-- 版块权限 -->
    <Modal v-model="showAuth">
      <div slot="header" style="color:#f60;text-align:left">
        <Icon type="ios-people"></Icon>設置權限
        <i-Switch v-model="enableAuth"></i-Switch>
      </div>
      <div v-for="(g, gi) in forumAuth" :key="gi">
        <Divider>{{g.name}}
          <Button :disabled="!enableAuth" @click="onAuthAll(g)"
                  size="small" icon="ios-checkmark-circle"></Button>
        </Divider>
        <Checkbox :disabled="!enableAuth" v-for="(v,k) in g.allows"
                  v-model="g.allows[k]" :key="k">{{authLabels[k]}}</Checkbox>

      </div>
      <Button slot="footer" type="primary" @click="setForumAuth">確定</Button>
    </Modal>
    <p style="color: orange;">請慎重操作！</p>
  </div>
</template>

<script>
  import util from '@/libs/util';
  import { httpGet, httpPost, httpDelete, httpSync, httpGetParams } from '@/api/data';

  export default {
    name: 'AdminForum',
    props: {
      value: Number, // 用于强制刷新数据
      show: Boolean
    },
    data () {
      return {
        user: this.$store.state.user,
        busy: false,
        validTime: 0,
        f_columns: [
          {
            title: 'ID',
            slot: 'id',
            width: 60
          },
          {
            title: '名稱',
            slot: 'name',
            width: 140
          },
          {
            title: '版主',
            slot: 'master',
            width: 100
          },
          {
            title: '簡介',
            slot: 'des'
          },
          {
            title: '公告',
            slot: 'notice'
          },
          {
            title: '操作',
            slot: 'action',
            width: 100
          }
        ],
        categories: [],
        // 新建对象的原型
        category_def: {
          id: 0,
          name: '',
          forums: []
        },
        forum_def: {
          id: 0,
          cid: 0,
          name: '',
          brief: null,
          announcement: null
        },

        edit_c: null,
        edit_f: null,
        edit_c_index: -1,
        edit_f_index: -1,
        move_forum: false,
        move_to_id: 0,

        // master
        selectMaster: false,
        user_field: 'uname',
        user_keyword: '',
        user_list: [],
        user_list_full: [],
        user_id: 0,

        // auth
        showAuth: false,
        enableAuth: false,
        globalAuth: null,
        forumAuth: null,
        authLabels: {
          allowread: '主題列表',
          allowpost: '查看帖子',
          allowthread: '發表/回復',

          allowattach: '上傳附件',
          allowdown: '下載附件'
          /*
          allowtop: '置頂',
          allowupdate: '修改',
          allowdelete: '刪除',
          allowmove: '移動',
          allowviewip: '查看IP'
 */
        }
      }
    },
    computed: {
      isAdmin () {
        return util.isAdmin(this.$store.state.user);
      },
      isSupper () {
        return util.isSupper(this.$store.state.user);
      }
    },
    methods: {
      refresh (force) {
        this.edit_c_index = -1;
        this.edit_f_index = -1;
        if (force || this.validTime < new Date().getTime()) {
          this.onRefresh();
        }
      },
      refreshTime () {
        this.validTime = new Date().getTime() + 60 * 1000;
      },
      onRefresh () {
        if (this.busy) {
          return;
        }
        this.busy = true;
        httpSync([
          httpGet('/v1/bbs/admin/categories'),
          httpGet('/v1/bbs/admin/forums')
        ], (resC, resF) => {
          const categories = resC;
          const map = {};
          // 为修改保存原始档
          for (const i in resC) {
            const c = resC[i];
            c.forums = [];
            map[c.id] = c;
          }
          for (const i in resF) {
            const forum = resF[i];
            if (!this.isSupper) {
              // 判断是否为本版版主
              let admin = false;
              for (const j in forum.masters) {
                if (forum.masters[j].id === this.user.id) {
                  admin = true;
                  break;
                }
              }
              if (!admin) {
                continue;
              }
            }
            const c = map[forum.cid];
            if (c) {
              c.forums.push(forum);
            }
          }
          this.onDataRefresh(categories);
          this.refreshTime();
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
        });
      },
      onDataRefresh (categories) {
        if (this.isSupper) {
          for (const i in categories) {
            const c = categories[i];
            const forum = { ...this.forum_def };
            forum.cid = c.id;
            c.forums.push(forum);
          }
          categories.push({ ...this.category_def });
          this.categories = categories;
        } else {
          this.categories.length = 0;
          for (const i in categories) {
            const c = categories[i];
            if (c.forums.length > 0) {
              this.categories.push(c);
            }
          }
        }
      },
      onEditCategory (index) {
        this.edit_c = { ...this.categories[index] };
        this.edit_c_index = index;
      },
      onSaveCategory (index) {
        if (this.busy || index < 0) {
          return;
        }
        if (this.edit_c_index >= 0) {
          if (index !== this.edit_c_index || !this.edit_c) {
            return;
          }
        }
        let apiUrl = '/v1/bbs/admin/category';
        const category = this.categories[index];
        const postData = {};

        // 比較是否有修改
        if (category.id > 0) {
          postData.name = this.edit_c.name;
          let same = true;
          for (const i in postData) {
            if (category[i] !== postData[i]) {
              same = false;
              break;
            }
          }
          if (same) {
            this.edit_c_index = -1;
            return;
          }
          apiUrl += '/' + category.id
        } else {
          postData.name = category.name;
        }
        // 比較是否有相同名稱
        for (const i in this.categories) {
          const raw = this.categories[i];
          if (raw.id === 0 || raw.id === this.edit_c.id) {
            continue;
          }
          if (raw.name === postData.name) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: '存在相同名稱！'
            });
            return;
          }
        }
        // 提交修改
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(res => {
          if (category.id <= 0) {
            // 补一个新的
            this.categories.push({ ...this.category_def });
          }
          this.categories[index] = res;
          this.edit_c_index = -1;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onDelCategory (index) {
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '將同時刪除該分類下的版塊，確定繼續？',
          onOk: () => {
            this.busy = true;
            const cid = this.categories[index].id;
            httpDelete('/v1/bbs/admin/category/' + cid)
              .then(() => {
                this.categories.splice(index, 1);
              })
              .catch(() => {})
              .finally(() => {
                this.busy = false;
              });
          }
        });
      },
      doMoveForum () {
        if (!this.move_forum || !this.busy) {
          return;
        }
        if (this.edit_c_index < 0 || this.edit_f_index < 0 || this.move_to_id <= 0) {
          return;
        }

        let moveToIndex = -1;
        for (const i in this.categories) {
          if (i !== this.edit_c_index && this.categories[i].id === this.move_to_id) {
            moveToIndex = i;
          }
        }
        if (moveToIndex < 0) {
          return;
        }
        const source = this.categories[this.edit_c_index];
        const forum = source.forums[this.edit_f_index];
        if (forum.id <= 0) {
          return;
        }
        const target = this.categories[moveToIndex];
        // 使用編輯功能實現
        const apiUrl = '/v1/bbs/admin/forum/' + forum.id;
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, { cid: this.move_to_id }).then(res => {
          source.forums.splice(this.edit_f_index, 1);
          target.forums.push(res);
        }).catch(() => {
        }).finally(() => {
          this.edit_c_index = -1;
          this.edit_f_index = -1;
          this.move_to_id = 0;
          this.move_forum = false;

          this.busy = false;
          this.$Spin.hide();
        });
      },
      onMoveForum (ci, index) {
        if (this.move_forum) {
          return;
        }
        if (this.categories.length <= 2) {
          return;
        }
        const c = this.categories[ci];
        this.edit_c_index = ci;
        this.edit_f_index = index;
        this.move_to_id = c.id;
        this.move_forum = true;
      },
      onEditForum (ci, index) {
        const forum = this.categories[ci].forums[index];
        this.edit_f = { ...forum };
        this.edit_c_index = ci;
        this.edit_f_index = index;
      },
      onSaveForum (ci, index) {
        if (this.busy) {
          return;
        }
        const category = this.categories[ci];
        const forum = category.forums[index];
        // 新建
        if (forum.id <= 0) {
          this.edit_f = forum;
        } else if (ci !== this.edit_c_index || index !== this.edit_f_index || !this.edit_f) {
          return;
        }

        // 比較是否有相同名稱
        for (const i in this.categories) {
          const c = this.categories[i];
          for (const j in c.forums) {
            const raw = c.forums[j];
            if (raw.id !== forum.id && raw.name === this.edit_f.name) {
              this.$Modal.warning({
                title: this.$t('common.alert_title'),
                content: '存在相同名稱！'
              });
              return;
            }
          }
        }

        let apiUrl = '/v1/bbs/admin/forum';
        const postData = {
          name: this.edit_f.name,
          brief: this.edit_f.brief,
          announcement: this.edit_f.announcement
        };
        if (forum.id <= 0) {
          postData.cid = this.edit_f.cid;
        } else {
          // 比較是否有修改
          let same = true;
          for (const i in postData) {
            if (forum[i] !== postData[i]) {
              same = false;
              break;
            }
          }
          if (same) {
            this.edit_c_index = -1;
            this.edit_f_index = -1;
            return;
          }
          apiUrl += '/' + forum.id
        }

        // 提交修改
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(res => {
          category.forums[index] = res;

          if (forum.id <= 0) {
            // 补一个新的
            const f = { ...this.forum_def };
            f.cid = category.id;
            category.forums.push(f);
          }
          this.edit_f_index = -1;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onDelForum (ci, index) {
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '確定刪除？',
          onOk: () => {
            this.busy = true;
            const c = this.categories[ci];
            const fid = c.forums[index].id;
            httpDelete('/v1/bbs/admin/forum/' + fid)
              .then(() => {
                c.forums.splice(index, 1);
              })
              .catch(() => {})
              .finally(() => {
                this.busy = false;
              });
          }
        });
      },
      onAuthAll (group) {
        let flag = false;
        for (const i in group.allows) {
          if (!group.allows[i]) {
            flag = true;
            break;
          }
        }
        for (const i in group.allows) {
          group.allows[i] = flag;
        }
      },
      onAuthForum (ci, index) {
        if (this.globalAuth) {
          this.showAuthForum(ci, index);
          return;
        }
        this.busy = true;
        httpGet('/v1/bbs/admin/groups').then(res => {
          const groups = [];
          for (const i in res) {
            const g = res[i];
            const item = {
              id: g.id,
              name: g.name,
              allows: {}
            };
            for (const j in this.authLabels) {
              item.allows[j] = g[j];
            }
            groups.push(item);
          }
          this.globalAuth = groups;
          this.forumAuth = JSON.parse(JSON.stringify(groups));
          this.showAuthForum(ci, index);
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
        });
      },
      showAuthForum (ci, index) {
        this.edit_c_index = -1;
        this.edit_f_index = -1;
        this.edit_f = this.categories[ci].forums[index];
        // 复制数据
        this.enableAuth = this.edit_f.accession;
        const access = this.edit_f.access;
        for (const i in this.forumAuth) {
          const group = this.forumAuth[i];
          let auths = access ? this.edit_f.access[group.id] : null;
          if (!auths) {
            auths = group.allows;
          }
          for (const k in group.allows) {
            group.allows[k] = auths[k];
          }
        }
        this.showAuth = true;
      },
      setForumAuth () {
        if (!this.edit_f) {
          this.showAuth = false;
          return;
        }
        const postData = {};
        if (!this.enableAuth) {
          postData.accession = false;
        } else {
          postData.access = {};
          for (const i in this.forumAuth) {
            const group = this.forumAuth[i];
            postData.access[group.id] = group.allows;
          }
          if (this.enableAuth !== this.edit_f.accession) {
            postData.accession = this.enableAuth;
          } else if (!postData.access) {
            this.edit_f = null;
            this.showAuth = false;
            return;
          }
        }

        const apiUrl = '/v1/bbs/admin/forum/' + this.edit_f.id;
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(res => {
          this.edit_f.accession = res.accession;
          this.edit_f.access = res.access;
          this.edit_f = null;
          this.showAuth = false;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onRemoveMaster (forum, index) {
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '確定移除？',
          onOk: () => {
            const uid = forum.masters[index].id;
            const ids = [];
            for (const i in forum.masters) {
              const user = forum.masters[i];
              if (user.id !== uid) {
                ids.push(user.id);
              }
            }
            this.doModifyMasters(forum, ids, (res) => {
              forum.masters = res.masters;
            });
          }
        });
      },
      onAddMaster (forum) {
        if (this.selectMaster) {
          return;
        }
        this.edit_c_index = -1;
        this.edit_f_index = -1;
        this.edit_f = forum;
        if (this.user_list_full.length > 0) {
          this.onUserListReady();
        }
        this.selectMaster = true;
      },
      onSearchUser () {
        const kw = this.user_keyword.trim();
        if (!kw) {
          return;
        }
        const params = {};
        params[this.user_field] = kw;
        params.page = 1;
        params.pageSize = 20;
        this.busy = true;
        // this.$Spin.show();
        httpGetParams('/v1/bbs/admin/users', params).then(res => {
          this.user_list_full = res.data;
          this.onUserListReady();
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          // this.$Spin.hide();
        });
      },
      onUserListReady () {
        const ids = [];
        for (const i in this.edit_f.masters) {
          ids.push(this.edit_f.masters[i].id);
        }
        const rs = [];
        for (const i in this.user_list_full) {
          const id = this.user_list_full[i].id;
          if (ids.indexOf(id) < 0) {
            rs.push(this.user_list_full[i]);
          }
        }
        this.user_list = rs;
        if (rs.length > 0) {
          this.user_id = rs[0].id;
        }
      },
      addMaster () {
        if (this.user_id > 0 && this.edit_f) {
          if (this.busy) {
            return false;
          }
          const ids = [this.user_id];
          this.doModifyMasters(this.edit_f, ids, (res) => {
            if (res) {
              this.edit_f.masters = res.masters;
              this.selectMaster = false;
            }
          });
        } else {
          this.selectMaster = false;
        }
      },
      doModifyMasters (forum, ids, cb) {
        const apiUrl = '/v1/bbs/admin/forum/' + forum.id;
        this.busy = true;
        // this.$Spin.show();
        httpPost(apiUrl, { master_ids: ids }).then(res => {
          cb(res);
        }).catch(() => {
          cb(null);
        }).finally(() => {
          this.busy = false;
          // this.$Spin.hide();
        });
      }
    },
    watch: {
      value () {
        this.refresh(true);
      },
      show (val) {
        if (val) {
          this.refresh();
        }
      }
    }
  }
</script>

<style scoped>
  .master_item {
    display: block;
    white-space:nowrap;
    border:lightgray solid 1px;
    border-radius: 5px;
    margin: 1px;
  }
</style>
