<template>
  <div v-if="show && validTime">
    <Card v-if="isSupper" style="margin-bottom: 5px;">
      <p slot="title" style="text-align: left;">主題類型</p>
      <Table :columns="type_columns" :data="type_list" border>
        <template slot-scope="{ row }" slot="id">
          <span v-if="row.id<=0">*</span>
          <span v-else>{{row.id}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="name">
          <Input v-if="row.id<=0" v-model="edit_type_name" style="width: 100px;" maxlength="8"></Input>
          <Input v-else-if="edit_t_index===index" v-model="edit_type.name" style="width: 100px;" maxlength="8"></Input>
          <span v-else>{{row.name}}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button @click="onSaveType(index)" v-if="row.id<=0 || edit_t_index===index" type="warning" size="small" icon="ios-checkmark-circle" style="margin-right: 5px"></Button>
          <Button @click="onEditType(index)" v-else-if="row.id>0" type="primary" size="small" icon="ios-create" style="margin-right: 5px"></Button>
          <Button @click="onDelType(index)" v-if="row.id > 0" type="error" size="small" icon="ios-trash"></Button>
        </template>
      </Table>
    </Card>

    <Card style="margin-bottom: 5px;">
      <p slot="title" style="text-align: left;">搜索條件</p>
      <Button slot="extra" type="primary" @click="onSearch">搜索</Button>
      <div style="display: table;">
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">版塊：</span>
          <span style="display: table-cell;width: 10px;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">關鍵詞：</span>
        </div>
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">
            <Select style="width: 200px;" v-model="forum_id">
              <i-option :value="0" :key="1000">所有版塊</i-option>
              <i-option v-for="(forum, fi) in forums" :value="forum.id" :key="fi">
                {{forum.name}}
              </i-option>
            </Select>
          </span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">
            <Input style="width: 300px;" v-model="keyword" autofocus></Input>
          </span>
        </div>

        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">開始時間：</span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">結束時間：</span>
        </div>
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">
            <DatePicker v-model="dt_start" type="date" placeholder="年-月-日" style="width: 200px;"></DatePicker>
          </span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">
            <DatePicker v-model="dt_end" type="date" placeholder="年-月-日" style="width: 200px;"></DatePicker>
          </span>
        </div>

        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">用戶名：</span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell;text-align: left;">發帖IP：</span>
        </div>
        <div style="display: table-row">
          <span style="display: table-cell;text-align: left;">
            <Input v-model="uname" style="width: 200px;"></Input>
          </span>
          <span style="display: table-cell;">&nbsp;</span>
          <span style="display: table-cell; text-align: left;">
            <Input v-model="ip" style="width: 200px;"></Input>
          </span>
        </div>
      </div>
    </Card>

    <Page :current="req_page" :total="totals" :page-size="page_size" @on-change="page_change"
          size="small" class="pager" style="text-align: right;"
          :transfer="true" show-elevator show-total/>
    <Table @on-selection-change="onSelectChanged" :columns="topic_columns" :data="topics" :loading="loading" border>
      <template slot-scope="{row}" slot="sel">
        <Checkbox v-model="row.selected"/>
      </template>
      <template slot-scope="{row}" slot="title">
        <Icon v-if="row.closed" type="ios-remove-circle" size="small"></Icon>
        <Icon v-else type="ios-document-outline" size="small"></Icon>
        <router-link :to="{name: 'topic', query: { id: row.id }, params: { topic: row }}">
          [{{row.flag.name}}]{{row.subject}}
        </router-link>
      </template>
      <template slot-scope="{row}" slot="user">
        <UserName :user="row.user"></UserName><br>
        <span style="font-size:x-small;">{{timeStamp(row.created_at)}}</span>
      </template>
      <template slot-scope="{row}" slot="stats">
        <span style="font-size:x-small;">{{row.posts}}</span><br>
        <span style="font-size:x-small;">{{row.views}}</span>
      </template>
      <template slot-scope="{row}" slot="last">
        <span v-if="row.last_user">
          <UserName :user="row.last_user"></UserName><br>
          <span style="font-size:x-small;">{{timeStamp(row.last_time)}}</span>
        </span>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button @click="onMoveTopic(index)" icon="ios-swap" type="primary" size="small" style="margin-right: 5px"></Button>
        <Button @click="onTurnTopic(index)" icon="ios-switch" type="primary" size="small" style="margin-right: 5px"></Button>
        <Button @click="onDelTopic(index)" icon="ios-trash" v-if="row.id > 0" type="error" size="small"></Button>
      </template>
    </Table>
    <Page :current="req_page" :total="totals" :page-size="page_size" @on-change="page_change"
          size="small" class="pager" style="text-align: right;"
          :transfer="true" show-elevator show-total/>
    <Button @click="onTurnTopic(-1, true)" icon="ios-unlock" type="primary" style="margin-right: 5px"></Button>
    <Button @click="onTurnTopic(-1, false)" icon="ios-lock" type="primary" style="margin-right: 5px"></Button>
    <Button @click="onDelTopic(-1)" icon="ios-trash" type="error"></Button>

    <Modal v-model="selectForum" width="230">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-people"></Icon>選擇版塊
      </p>
      <div>
        <Select style="width: 200px;" v-model="move_forum_id">
          <i-option v-for="(f, fi) in forums" :value="f.id" :key="fi">
            {{f.name}}
          </i-option>
        </Select>
      </div>
      <Button slot="footer" type="primary" @click="setForum">設置</Button>
    </Modal>
  </div>
</template>

<script>
  import util from '@/libs/util';
  import { httpGet, httpGetParams, httpPost, httpDelete } from '@/api/data';
  import UserName from '../parts/user_name';

  export default {
    name: 'AdminTopic',
    components: { UserName },
    props: {
      value: Number, // 用于强制刷新
      show: Boolean
    },
    data () {
      return {
        user: this.$store.state.user,
        validTime: 0,
        type_columns: [
          {
            title: 'ID',
            slot: 'id',
            width: 60
          },
          {
            title: '名稱',
            slot: 'name'
          },
          {
            title: '操作',
            slot: 'action',
            width: 90
          }
        ],
        type_list: [],
        type_def: {
          id: 0,
          name: ''
        },
        edit_type_name: '',
        edit_type: null,
        edit_t_index: -1,
        busy: false,

        loading: false,
        forum_id: 0,
        forums: [],
        keyword: '',
        dt_start: null,
        dt_end: null,
        uname: null,
        ip: null,
        topics: [],
        topic_columns: [
          {
            type: 'selection',
            width: 50
          },
          {
            title: '標題',
            slot: 'title',
            ellipsis: true
          },
          {
            title: '發帖人',
            slot: 'user',
            width: 100
          },
          {
            title: '回复/查看',
            slot: 'stats'
          },
          {
            title: '最后回复',
            slot: 'last'
          },
          {
            title: '操作',
            slot: 'action',
            width: 135
          }
        ],
        totals: 0,
        page_size: 10,
        curr_page: 1,
        req_page: 1,

        selection: [],
        edit_index: -1,
        edit_forum: null,
        selectForum: false,
        move_forum_id: 0
      }
    },
    computed: {
      isAdmin () {
        return util.isAdmin(this.$store.state.user);
      },
      isSupper () {
        return util.isSupper(this.$store.state.user);
      }
    },
    methods: {
      refresh (force) {
        this.edit_t_index = -1;
        if (force || this.validTime < new Date().getTime()) {
          this.onRefresh();
        }
      },
      refreshTime () {
        this.validTime = new Date().getTime() + 60 * 1000;
      },
      onRefresh () {
        if (this.busy) {
          return;
        }
        this.busy = true;
        this.type_list.splice(0);
        httpGet('/v1/bbs/admin/flags').then(res => {
          this.type_list = res;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.type_list.push({ ...this.type_def });
          this.refreshTime();
        });
        httpGet('/v1/bbs/admin/forums').then(res => {
          this.forums = res;
        }).catch(() => {
        });
      },
      onEditType (index) {
        this.edit_t_index = index;
        this.edit_type = { ...this.type_list[index] };
      },
      onSaveType (index) {
        if (this.busy || index < 0) {
          return;
        }
        const type = this.type_list[index];
        if (type.id <= 0) {
          this.edit_t_index = -1;
          this.edit_type = type;
        } else if (index !== this.edit_t_index || !this.edit_type) {
          return;
        }
        let apiUrl = '/v1/bbs/admin/flag';
        const postData = {};

        // 比較是否有修改
        if (type.id > 0) {
          postData.name = this.edit_type.name;
          let same = true;
          for (const i in postData) {
            if (type[i] !== postData[i]) {
              same = false;
              break;
            }
          }
          if (same) {
            this.edit_t_index = -1;
            return;
          }
          apiUrl += '/' + type.id
        } else {
          postData.name = this.edit_type_name;
        }
        // 比較是否有相同名稱
        for (const i in this.type_list) {
          const raw = this.type_list[i];
          if (raw.id <= 0 || raw.id === this.edit_type.id) {
            continue;
          }
          if (raw.name === postData.name) {
            this.$Modal.warning({
              title: this.$t('common.alert_title'),
              content: '存在相同名稱！'
            });
            return;
          }
        }
        // 提交修改
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(res => {
          this.edit_type_name = '';
          if (type.id <= 0) {
            // 补一个新的
            this.type_list.push({ ...this.type_def });
          }
          this.type_list[index] = res;
          this.edit_t_index = -1;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onDelType (/* index */) {
        this.$Modal.warning({
          title: this.$t('common.alert_title'),
          content: '暫不支持刪除！'
        });
      },
      page_change (page) {
        this.req_page = page;
        this.onSearch();
      },
      timeStamp (tt) {
        return util.showTimeStamp(tt, true, 0);
      },
      onSearch () {
        if (this.loading) {
          return;
        }
        this.topics.length = 0;
        const params = {};
        if (this.forum_id > 0) {
          params.fid = this.forum_id;
        }
        const kw = this.keyword.trim();
        if (kw) {
          params.subject = kw;
        }
        if (this.dt_start) {
          params.dates = [];
          params.dates.push(this.dt_start);
        }
        if (this.dt_end) {
          if (!params.dates) {
            params.dates = [new Date(0)];
          }
          params.dates.push(this.dt_start);
        }
        if (this.uname) {
          params.userinfo = this.uname;
        }
        if (this.ip) {
          params.ip = this.ip;
        }
        if (JSON.stringify(params).length < 3) {
          this.$Modal.warning({
            title: this.$t('common.alert_title'),
            content: '請設置搜索條件！'
          });
          return;
        }
        params.page = this.req_page;
        params.pageSize = this.page_size;
        this.loading = true;
        this.$Spin.show();
        httpGetParams('/v1/bbs/admin/topics', params).then(res => {
          this.topics = res.data;
          this.curr_page = res.current_page;
          this.totals = res.total;
        }).catch(() => {
        }).finally(() => {
          this.loading = false;
          this.$Spin.hide();
        });
      },
      onSelectChanged (selection) {
        this.selection = selection;
      },
      onMoveTopic (index) {
        if (index < 0) {
          return;
        }
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '確定移動主題？',
          onOk: () => {
            this.edit_index = index;
            const forum = this.forums[index];
            this.move_forum_id = forum.id;
            this.selectForum = true;
          }
        });
      },
      setForum () {
        if (this.edit_index < 0) {
          this.selectForum = false;
          return;
        }
        const forum = this.forums[this.edit_index];
        if (this.move_forum_id === forum.id) {
          this.edit_index = -1;
          this.selectForum = false;
          return;
        }
        // todo: 待實現
        const apiUrl = '';
        const postData = {};
        // 提交修改
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, postData).then(() => {
          this.edit_index = -1;
          this.selectForum = false;
        }).catch(() => {
        }).finally(() => {
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onTurnTopic (index, unlock) {
        if (index < 0 && this.selection.length === 0) {
          return;
        }
        const ids = [];
        if (index > 0) {
          const topic = this.topics[index];
          unlock = topic.closed;
          ids.push(topic.id);
        } else {
          for (const i in this.selection) {
            const topic = this.selection[i];
            if (topic.closed !== closed) {
              ids.push(topic.id);
            }
          }
          if (ids.length === 0) {
            return;
          }
        }
        if (!unlock) {
          this.$Modal.confirm({
            title: this.$t('common.alert_title'),
            content: '確定關閉主題？',
            onOk: () => {
              const apiUrl = '/v1/bbs/admin/topics/close';
              this.doTurnTopic(apiUrl, ids, index, true);
            }
          });
        } else {
          const apiUrl = '/v1/bbs/admin/topics/open';
          this.doTurnTopic(apiUrl, ids, index, false);
        }
      },
      doTurnTopic (apiUrl, ids, index, closed) {
        this.busy = true;
        this.$Spin.show();
        httpPost(apiUrl, { ids: ids }).then(() => {
          if (index < 0) {
            for (const i in this.selection) {
              this.selection[i].closed = closed;
            }
          } else {
            const topic = this.topics[index];
            topic.closed = closed;
          }
        }).catch(() => {
        }).finally(() => {
          this.edit_index = -1;
          this.busy = false;
          this.$Spin.hide();
        });
      },
      onDelTopic (index) {
        if (index < 0 && this.selection.length === 0) {
          return;
        }
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: '將同時刪除該主題下的回帖，確定繼續？',
          onOk: () => {
            const apiUrl = '/v1/bbs/admin/topics';
            const ids = [];
            if (index < 0) {
              for (const i in this.selection) {
                ids.push(this.selection[i].id);
              }
            } else {
              const topic = this.topics[index];
              ids.push(topic.id);
            }
            this.busy = true;
            this.$Spin.show();
            httpDelete(apiUrl, { ids: ids }).then(() => {
              if (index < 0) {
                const leaves = [];
                for (const i in this.selection) {
                  this.selection[i].id = -1;
                }
                for (const i in this.topics) {
                  if (this.topics[i].id > 0) {
                    leaves.push(this.topics[i]);
                  }
                }
                this.topics = leaves;
                this.selection.length = 0;
              } else {
                this.topics.splice(index, 1);
              }
            }).catch(() => {
            }).finally(() => {
              this.busy = false;
              this.$Spin.hide();
            });
          }
        });
      }
    },
    watch: {
      value () {
        this.refresh(true);
      },
      show (val) {
        if (val) {
          this.refresh();
        }
      }
    }
  }
</script>

<style scoped>
</style>
